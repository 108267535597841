import type { User } from '@prisma/client'
import { useEffect } from 'react'

export function useSessionRewind({
  user,
  enabled,
}: {
  user?: Pick<User, 'email' | 'firstName' | 'lastName'>
  enabled: boolean
}) {
  useEffect(() => {
    if (!enabled) {
      return
    }

    if ((window as any).SessionRewindConfig) {
      // session rewind already initialized
      return
    }

    const url = 'https://rec.sessionrewind.com/srloader.js'

    ;(window as any).SessionRewindConfig = {
      // Required
      apiKey: 'zNk0wEygvu75TfCiCUDX66u7SuSF6zKC5xZ2TfkD',

      // Optional but if false or undefined the recording will need to be initialized later via window.sessionRewind.startSession()
      startRecording: true,

      // OPTIONAL: If set to true, resets the session recording ID on page load
      createNewSession: false,

      // OPTIONAL: Custom session metadata
      // sessionInfo: {
      //   customFieldA: 'This is a custom field' // Example custom field
      // },

      // OPTIONAL: Custom user metadata
      userInfo: user
        ? {
            userId: user.email,
            userName: `${user.firstName} ${user.lastName}`,
          }
        : {},
    }

    const head = document.querySelector('head')!
    const script = document.createElement('script')

    script.setAttribute('src', url)
    script.async = true
    script.crossOrigin = 'anonymous'
    head.appendChild(script)

    return () => {
      head.removeChild(script)
    }
  }, [user, enabled])
}
